






































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getSchoolList, getSchoolCascadeList } from "@/api/school";
import { getExamRoomList } from "@/api/examRoom";
import { importExamStudent } from "@/api/examStudent";
import XLSX from "xlsx";
import { ExamRoom, ExamStudent } from "@/tool/interface-index";
import { ExamSchool, ExamProjectPro } from "@/tool/_class";
import * as _ from "lodash";

@Component({
  name: "ImportStudents",
})
export default class extends Vue {
  @Prop() private examProjectProfile!: ExamProjectPro;
  private schools: Array<ExamSchool> = [];
  private examRooms: Array<ExamRoom> = [];
  //private school: any = {};
  private fileName: string = "";
  private loading: boolean = false;
  private xlxsList: {
    examProjectId: string;
    examSchoolId: string;
    examRoomIds: string[];
    items: ExamStudent[];
  } = {
    examProjectId: "",
    examSchoolId: "",
    examRoomIds: [],
    items: [],
  };

  private checkedFields: string[] = [
    "examAccount",
    "studentName",
    "sex",
    "schoolName",
    "className",
    "photoFileName",
    //"str01",
  ];

  private examStudentFields: {
    label: string;
    disabled: boolean;
    value: string;
  }[] = [
    {
      label: "考号",
      value: "examAccount",
      disabled: true,
    },
    {
      label: "姓名",
      value: "studentName",
      disabled: true,
    },
    {
      label: "性别",
      value: "sex",
      disabled: false,
    },
    {
      label: "学校",
      value: "schoolName",
      disabled: true,
    },
    {
      label: "班级",
      value: "className",
      disabled: true,
    },
    /*
    {
      label: "考点名称",
      value: "examSchoolName",
      disabled: true
    },
    */
    {
      label: "相片",
      value: "photoFileName",
      disabled: false,
    },
    {
      label: "座位号",
      value: "str01",
      disabled: false,
    },
  ];

  private cascadeExamSchoolId: string[] = [];
  private examSchools: any[] = [];
  private examSchoolName: string = "";

  private get disabled(): boolean {
    if (!this.cascadeExamSchoolId.length || !this.xlxsList.items.length) {
      return true;
    }
    return false;
  }

  private cascaderItemLabel(data: any) {
    let result = data.keyValue;
    if (data?.dataType == "school") {
      const idx = this.schools.findIndex((school: any) => {
        return school.schoolId == data.keyId;
      });
      if (idx >= 0) {
        if (_.get(this.schools[idx], "highSchool", false)) {
          result = "[高] " + result;
        }
        if (_.get(this.schools[idx], "middleSchool", false)) {
          result = "[中] " + result;
        }
        if (_.get(this.schools[idx], "primarySchool", false)) {
          result = "[小] " + result;
        }
      }
    }
    return result;
  }

  private filterMethod(node: any, keyword: string) {
    let data = node.data;
    if (!keyword.trim()) {
      return true;
    }
    //
    let keyWords: string[] = keyword.split(" ");
    if (!keyWords.length) {
      keyWords = keyword.split(",");
    }
    if (!keyWords.length) {
      keyWords = keyword.split(";");
    }
    //
    let ss: string = data.keyValue;
    const idx = this.schools.findIndex((school: any) => {
      return school.schoolId == data.keyId;
    });
    if (idx >= 0) {
      let schoolSections: string[] = [];
      if (_.get(this.schools[idx], "primarySchool", false)) {
        schoolSections.push("小学");
      }
      if (_.get(this.schools[idx], "middleSchool", false)) {
        schoolSections.push("中学");
      }
      if (_.get(this.schools[idx], "highSchool", false)) {
        schoolSections.push("高中");
      }
      ss = `${_.get(this.schools[idx], "schoolName")}-${schoolSections.join(
        "|"
      )}-${_.get(this.schools[idx], "areaName")}`;
    }
    //
    console.log(`ss:${ss}`);
    //
    let result: boolean = true;
    for (let i = 0; i < keyWords.length; i++) {
      result = result && ss.indexOf(keyWords[i]) > -1;
    }
    return result;
  }

  private async sub() {
    try {
      this.loading = true;
      //数据完整性判断
      for (let i = 0; i < this.xlxsList.items.length; i++) {
        let item = this.xlxsList.items[i];
        if (
          this.checkedFields.some((field) => {
            return _.get(item, field, "") == "";
          })
        ) {
          //alert(JSON.stringify(item));
          throw new Error(`第${i + 1}行数据不完整。`);
        }
      }

      //2023/04/24增加重复考号检测
      const uniqItems = _.unionBy(this.xlxsList.items, "examAccount");
      if (
        this.xlxsList.items.length &&
        this.xlxsList.items.length !== uniqItems.length
      ) {
        throw new Error(`存在重复的考号，请核查后再试。`);
      }
      //2024/09/03增加未选择的栏位，但xls有值提示
      //...

      //开始数据准备及处理 ...
      this.xlxsList.examProjectId = this.examProjectProfile.examProjectId;
      this.xlxsList.examSchoolId = _.last(this.cascadeExamSchoolId) as string;

      const { data } = await importExamStudent(this.xlxsList);

      this.loading = false;

      const school: ExamSchool = new ExamSchool();
      school.schoolId = _.last(this.cascadeExamSchoolId) as string;
      school.standardExamStudents = this.xlxsList.items.length;
      school.schoolName = this.examSchoolName;
      this.examProjectProfile.examSchools.push(
        _.merge(new ExamSchool(), school)
      );
      //添加考场信息到Profile
      let examRooms = this.examRooms.filter((item) => {
        return this.xlxsList.examRoomIds.some((examRoomId: string) => {
          return examRoomId == item.examRoomId;
        });
      });
      examRooms.forEach((item) => {
        if (
          !this.examProjectProfile.examRooms.some((item1) => {
            return item.examRoomId == item1.examRoomId;
          })
        ) {
          this.examProjectProfile.examRooms.push(item);
        }
      });
      //如果不选考场，删除Profile这个考点对应的考场
      if (examRooms.length == 0) {
        let examRooms = this.examProjectProfile.examRooms.filter((item) => {
          return item.schoolId == this.xlxsList.examSchoolId;
        });
        examRooms.forEach((item) => {
          let idx = this.examProjectProfile.examRooms.findIndex((item1) => {
            return item.examRoomId == item1.examRoomId;
          });
          this.examProjectProfile.examRooms.splice(idx, 1);
        });
      }
      //
      this.$message({
        type: "success",
        message: "添加考生成功",
      });
      this.$emit("closeShowDialog");
    } catch (error: any) {
      this.$message({
        type: "error",
        message: error.message || error.msg || "导入出错。",
      });
    } finally {
      this.loading = false;
    }
  }

  private async getExamRoomListData(schoolId: string) {
    try {
      this.loading = true;
      const res = await getExamRoomList({
        pageSize: 9999,
        curPage: 1,
        schoolId,
      });
      this.examRooms = res.data.items;
    } finally {
      this.loading = false;
    }
  }

  private getSchoolListData() {
    getSchoolList({ pageSize: 9999, curPage: 1 }).then((res) => {
      this.schools = res.data.items;
    });
  }

  private handleChangeFile(file: any, fileList: any[]) {
    if (fileList.length <= 0) {
      return;
    }
    let fileName: string = _.replace(
      _.replace(fileList[0].name, ".xlsx", ""),
      ".xls",
      ""
    );
    const names: string[] = fileName.split("_");
    if (names.length > 0) {
      //在文件名切片中尽量找出学校名
      let schoolName: string =
        names.find((item) => {
          for (let i = 0; i < item.length; i++) {
            if (
              ["小", "中", "高", "学", "校", "书", "院"].some(
                (key) => key == item[i]
              )
            ) {
              return true;
            }
          }
          return false;
        }) || names[names.length - 1];
      //去掉可能不是名称的字符
      const exStartWithChars: string[] = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ];
      while (schoolName.length) {
        let hasStartWith: boolean = false;
        let hasEndWith: boolean = false;
        for (let i = 0; i < exStartWithChars.length; i++) {
          if (schoolName[0] == exStartWithChars[i]) {
            hasStartWith = true;
            break;
          }
        }
        if (hasStartWith) {
          const end = schoolName.length;
          schoolName = schoolName.substring(1, end);
        }
        if (hasEndWith) {
          const end = schoolName.length - 1;
          schoolName = schoolName.substring(0, end);
        }
        //...
        if (!hasStartWith && !hasEndWith) {
          break;
        }
      }
      //
      if (!schoolName) {
        return;
      }

      //
      let idx: number = -1;
      //1.全匹配检查
      idx = this.schools.findIndex((school) => {
        return school.schoolName == schoolName;
      });
      //
      const filter_sub_includes = (a: string, b: string): boolean => {
        return a != "" && b != "" && (a.includes(b) || b.includes(a));
      };
      const filter_sub_hasAllChars = (a: string, b: string): boolean => {
        if (a == "" || b == "") {
          return false;
        }
        let str0: string = "";
        let str1: string = "";
        if (a.length == b.length) {
          str0 = a;
          str1 = b;
        } else {
          str0 = a.length > b.length ? a : b;
          str1 = a.length < b.length ? a : b;
        }
        for (let i = 0; i < str1.length; i++) {
          let has: boolean = false;
          for (let j = 0; j < str0.length; j++) {
            if (str0[j] == str1[i]) {
              has = true;
              break;
            }
          }
          if (!has) {
            return false;
          }
        }
        return true;
      };
      //2.学校名：互相任一包含
      if (idx < 0) {
        const bySchoolNameIncludes: ExamSchool[] = this.schools.filter(
          (school) => {
            return filter_sub_includes(school.schoolName, schoolName);
          }
        );
        if (bySchoolNameIncludes.length == 1) {
          //找到唯一的话，视为对应上了，
          idx = this.schools.findIndex(
            (school) => school.schoolId == bySchoolNameIncludes[0].schoolId
          );
        }
      }
      //3.学校名：短相短的名称是否在长的名称全出现
      if (idx < 0) {
        const bySchoolNameIncludes: ExamSchool[] = this.schools.filter(
          (school) => {
            return filter_sub_hasAllChars(school.schoolName, schoolName);
          }
        ); //filter
        if (bySchoolNameIncludes.length == 1) {
          //找到唯一的话，视为对应上了，
          idx = this.schools.findIndex(
            (school) => school.schoolId == bySchoolNameIncludes[0].schoolId
          );
        }
      }
      //4.学校别名：互相任一包含
      if (idx < 0) {
        const bySchoolNameIncludes: ExamSchool[] = this.schools.filter(
          (school) => {
            return filter_sub_includes(school.str03, schoolName);
          }
        );
        if (bySchoolNameIncludes.length == 1) {
          //找到唯一的话，视为对应上了，
          idx = this.schools.findIndex(
            (school) => school.schoolId == bySchoolNameIncludes[0].schoolId
          );
        }
      }
      //5.学校别名：短相短的名称是否在长的名称全出现
      if (idx < 0) {
        const bySchoolNameIncludes: ExamSchool[] = this.schools.filter(
          (school) => {
            return filter_sub_hasAllChars(school.str03, schoolName);
          }
        ); //filter
        if (bySchoolNameIncludes.length == 1) {
          //找到唯一的话，视为对应上了，
          idx = this.schools.findIndex(
            (school) => school.schoolId == bySchoolNameIncludes[0].schoolId
          );
        }
      }

      //n.找到对应的，则付值
      if (idx >= 0) {
        this.cascadeExamSchoolId = this.getCascadeSchoolId(
          this.schools[idx].schoolId
        );
        this.handleExamSchoolChange(this.cascadeExamSchoolId);
      }
    }
  }
  private async beforeUpload(file: any) {
    const items: any = await this.readXLSX(file);
    this.xlxsList.items = items.map((item: any, index: number) => {
      let result = {
        examAccount: "",
        studentName: "",
        sex: "",
        schoolName: "",
        className: "",
        photoFileName: "",
        str01: "",
      };

      Object.keys(item).forEach((key, index) => {
        //console.log(`${index}, ${key}, item: ${item[key]}`);
        switch (key) {
          case "考号": {
            result.examAccount = item[key].toString() || "";
            break;
          }
          case "姓名": {
            result.studentName = item[key].toString() || "";
            break;
          }
          case "性别": {
            result.sex = item[key].toString() || "";
            break;
          }
          case "学校": {
            result.schoolName = item[key].toString() || "";
            break;
          }
          case "班级": {
            result.className = item[key].toString() || "";
            break;
          }
          case "相片": {
            result.photoFileName = item[key].toString() || "";
            break;
          }
          case "座位号": {
            result.str01 = item[key].toString() || "";
            break;
          }
        }
      });

      //console.log(`result: ${JSON.stringify(result)}`);

      return result;
      /*
        return {
          examAccount: item[Object.keys(item)[0]].toString(),
          studentName: item[Object.keys(item)[1]].toString(),
          sex: item[Object.keys(item)[2]].toString(),
          schoolName: item[Object.keys(item)[3]].toString(),
          className: item[Object.keys(item)[4]].toString(),
          photoFileName: item[Object.keys(item)[6]].toString(),
        };
        */
    }) as Array<ExamStudent>; //读取到的内容
  }
  private importExamStudentData(data: any) {}

  private readXLSX(file: any) {
    let nameSplit = file.name.split(".");
    this.fileName = file.name;
    let format = nameSplit[nameSplit.length - 1];
    if (!["xlsx", "xls"].includes(format)) {
      return false;
    }
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (evt: any) => {
        let data = evt.target.result; // 读到的数据
        let workbook = XLSX.read(data, { type: "binary" });
        let firstSheetName = workbook.SheetNames[0];
        // let worksheet = workbook.Sheets[firstSheetName];
        // this.tableHeader = this.getHeaderRow(worksheet);
        let exlname = workbook.SheetNames[0]; // 取第一张表
        let exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
        resolve(exl);
      };
    });
  }

  private getCascadeItem(keyId: string, cascades: any[]): any {
    let result = undefined;
    cascades.some(function iter(obj) {
      if (obj.keyId == keyId) {
        result = obj;
        return true;
      }
      return Array.isArray(obj.children) && obj.children.some(iter);
    });
    return result;
  }

  private getCascadeSchoolId(schoolId: string): string[] {
    let arr: string[] = [];
    let keyId: string = schoolId;
    do {
      const item = this.getCascadeItem(keyId, this.examSchools);
      if (item) {
        arr.push(keyId);
        keyId = item.parentKeyId;

        continue;
      }
      break;
    } while (true);
    return arr.reverse();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  mounted() {
    this.getSchoolListData();

    getSchoolCascadeList().then(({ data }) => {
      this.examSchools = data.items;
      this.removeNullChildren(this.examSchools);
    });
    if (
      this.examProjectProfile.examParams.studentExamPaperRule.toLowerCase() ==
      "examStudentSeatNoFirst".toLowerCase()
    ) {
      //如果按座位号排卷的，
      if (!this.checkedFields.some((item) => item == "str01")) {
        this.checkedFields.push("str01");
      }
      const field = this.examStudentFields.find(
        (item) => item.value == "str01"
      );
      if (field) {
        field.disabled = true;
      }
    }
  }
  private async handleExamSchoolChange(value: string[]) {
    const schoolId = _.last(value) || "";
    const cascadeItem = this.getCascadeItem(schoolId, this.examSchools);
    console.log(
      `handleExamSchoolChange: ${value};schoolId:${schoolId};cascadeItem:${
        cascadeItem ? JSON.stringify(cascadeItem) : ""
      }`
    );
    this.examSchoolName = cascadeItem ? cascadeItem.keyValue : "";
    await this.getExamRoomListData(schoolId);
    this.xlxsList.examRoomIds = this.examProjectProfile.examRooms
      .filter((item) => {
        return item.schoolId == schoolId;
      })
      .map((item) => {
        return item.examRoomId;
      });
  }
}
